function highlightTextOccurrence(text: string, searchIn: string) {
  const matchStart = text.toLowerCase().indexOf(searchIn.toLowerCase());

  if (matchStart >= 0) {
    const matchEnd = matchStart + searchIn.length;
    const replaceSubstring = text.substring(matchStart, matchEnd);
    return text.replace(replaceSubstring, `<b>${replaceSubstring}</b>`);
  }

  return text;
}

export default highlightTextOccurrence;
